import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import { useState } from 'react';

export const PasswordVisibilityInput = (props) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handleClickShowPassword = () => {
        setIsPasswordVisible(prevState => !prevState);
    }

    return (<Input
        {...props}
        type={isPasswordVisible ? 'text' : 'password'}
        wrapClassName='focus-within:border-blue-100 focus:border-blue-500'>
        <Input.Suffix>
            <Button aria-label='toggle password visibility' className="bg-transparent border-none" variant="outline" onClick={handleClickShowPassword} >
                <Icon width={24} height={24} name={isPasswordVisible ? "EyeEmpty" : "EyeOff"} className="mx-auto" />
            </Button>
        </Input.Suffix>
    </Input>)
}