import Button from "components/dist/atoms/Button";
import { NavArrowLeftIcon } from "src/icons/nav-arrow-left-icon";
import { classNames } from "src/utils/tailwind-class-names";

interface BackButtonProps {
    onClick: () => void;
    variant?: 'outline' | 'secondary';
    className?: string;
}

export const BackButton = (props: BackButtonProps) => {
    const { onClick } = props;
    return <Button
        onClick={onClick}
        variant={props.variant ?? 'outline'}
        className={classNames("flex items-center gap-1 h-8 pl-2 pr-3.5", props.className)}>
        <NavArrowLeftIcon /> Back
    </Button>
}