import * as Yup from 'yup';

export const confirmSignUpFormValidationSchema = Yup
    .object()
    .shape({
        username: Yup
            .string()
            .min(3, 'Must be at least 3 characters')
            .max(255)
            .required('Required'),
        // password: Yup
        //     .string()
        //     .min(6, 'Must be at least 6 characters')
        //     .max(255)
        //     .required('Required'),
        confirmationCode: Yup
            .string()
            .required("This field is required")
    })
