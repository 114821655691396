import * as React from 'react';
import {
    SignUpForm,
    SignUpFormValues,
} from 'src/components/auth/sign-up-form/sign-up-form';
import { SignUpOptionsForm } from 'src/components/auth/sign-up-options-form';
import ConfirmSignUpForm from 'src/components/auth/tmp-confirm-signup-form/confirm-sign-up-form';

type Props = {
    signUpFormValues: SignUpFormValues | null;
    setSignUpFormValues: (values: SignUpFormValues | null) => void;
    title?: string;
    showLogo?: boolean;
    showBackButton?: boolean;
    showLoginLink?: boolean;
};

const SignupPage: React.FC<Props> = ({
    signUpFormValues,
    setSignUpFormValues,
    title = 'Sign up',
    showLogo = true,
    showBackButton = true,
    showLoginLink = true,
}) => {
    const [signUpWithEmail, setSignUpWithEmail] = React.useState<boolean>(false);

    const handleSignUpWithEmailClick = () => {
        setSignUpWithEmail(true);
    };

    const handleSignUpSuccess = (values: SignUpFormValues) => {
        setSignUpFormValues(values);
    };

    const handleReturnToSignUp = () => {
        setSignUpFormValues(null);
    };

    const handleBackClick = () => {
        setSignUpWithEmail(false);
    };
    return (
        <>
            {!signUpWithEmail && !signUpFormValues && (
                <SignUpOptionsForm
                    showLoginLink={showLoginLink}
                    showBackButton={showBackButton}
                    showLogo={showLogo}
                    title={title}
                    onSignUpWithEmailClick={handleSignUpWithEmailClick}
                />
            )}
            {!signUpFormValues && signUpWithEmail && (
                <SignUpForm
                    showBackButton={showBackButton}
                    showLogo={showLogo}
                    onBackClick={handleBackClick}
                    onSubmitSuccess={handleSignUpSuccess}
                />
            )}
            {!!signUpFormValues && (
                <ConfirmSignUpForm
                    showBackButton={showBackButton}
                    onBack={handleReturnToSignUp}
                    username={signUpFormValues?.username}
                    password={signUpFormValues?.password}
                    provider={signUpFormValues?.provider}
                />
            )}
        </>
    );
};

export default SignupPage;
