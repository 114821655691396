import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Route } from 'src/constants/ui';
import { useAuth } from 'src/hooks/use-auth';
import { useGetAppVersionQuery } from 'src/services/appApi';

import { AppStartupScreen } from '../app-startup-screen';
import { SplashScreen } from '../splash-screen';

interface GuestGuardProps {
    children: JSX.Element;
}

export const GuestGuard: FC<GuestGuardProps> = (props) => {
    const getAppVersion = useGetAppVersionQuery(null, {
        pollingInterval: 0
    });
    const auth = useAuth();
    const { isReady, replace, query } = useRouter();
    const [checked, setChecked] = useState(false);

    const isPasswordExpiring = !!auth.user?.isPasswordExpiring;
    const { isAuthenticated, isInitialized } = auth;

    useEffect(
        () => {
            if (!isReady || !isInitialized) {
                return;
            }
            if (isAuthenticated && isInitialized) {
                if (!auth.user?.loggedRoleGroup) {
                    replace({
                        pathname: Route.AUTH_LOAN_ROLE_VERIFICATION,
                        query
                    });
                } else {
                    replace({
                        pathname: Route.HOME,
                        query
                    });
                }
            } else if (!isAuthenticated && isInitialized) {
                setChecked(true);
            }
        },
        [isAuthenticated, isInitialized, isReady, replace, query, isPasswordExpiring, auth.user?.loggedRoleGroup],
    );

    if (!checked) {
        return <SplashScreen />;
    }

    if (!getAppVersion.isSuccess || !getAppVersion.data) {
        return <AppStartupScreen />;
    }

    // If got here, it means that the redirect did not occur, and that tells us that the user is
    // not authenticated / authorized.

    return <>{props.children}</>;
};