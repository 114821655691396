import { FormikErrors } from 'formik';
import { getString } from 'src/i18n/labels';
import * as Yup from 'yup';

export const yupPasswordValidation = Yup
    .string()
    .required('Required field')
    .min(8, 'Must be at least 8 characters')
    .matches(/\d/, 'Must contain at least one number')
    .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, 'Must contain at least one special character')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .max(255, getString('max255'));

export function FormikAsyncValidateSchema<FormState extends object>(
    schema: Yup.ObjectSchema<any>
): (values: FormState) => Promise<FormikErrors<FormState>> {
    return async function (values: FormState): Promise<FormikErrors<FormState>> {
        try {
            await schema.validate(values, { abortEarly: false, strict: false, });
            return Promise.resolve({});
        } catch ({ inner }) {
            return inner.reduce((memo, { path, message }) => ({ ...memo, [path]: (memo[path] || []).concat(message), }), {});
        }
    };
}