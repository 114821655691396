import { yupPasswordValidation } from 'src/utils/yup-validation';
import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Required field';

export const signUpFormValidationSchema = Yup
    .object()
    .shape({
        password: yupPasswordValidation,
        email: Yup
            .string()
            .email("Invalid email address")
            .required(REQUIRED_MESSAGE),
        givenName: Yup.string()
            .required(REQUIRED_MESSAGE),
        familyName: Yup.string()
            .required(REQUIRED_MESSAGE),
    })
