import Box from '@mui/material/Box';
import Button from "components/dist/atoms/Button";
import Separator from "components/dist/atoms/Separator";
import Text from 'components/dist/atoms/Text';
import { format } from 'date-fns';
import { FormikHelpers, useFormik } from 'formik';
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import VerificationInput from "react-verification-input";
import { api } from "src/api";
import { BackButton } from "src/components/common/back-button";
import { MysherpasTooltip } from "src/components/common/mysherps-tooltip/mysherpas-tooltip.component";
import { Route } from "src/constants/ui";
import { AuthContext } from "src/contexts/auth-context";
import { useSubdomainLender } from "src/contexts/subdomain-lender-context";
import { themeV2 } from "src/theme/mysherpas-theme-option";
import { ConfirmSignupRequest } from "src/types/auth";
import { getPrettyCountdownTime } from 'src/utils/get-pretty-countdown-time';
import { toast } from "src/utils/toast";
import { useCountdown } from 'usehooks-ts'

import { CompanyLogo } from "../company-logo";
import { confirmSignUpFormValidationSchema } from "./confirm-sign-up-form-validation";

interface Props {
    username?: string;
    password?: string;
    provider?: string;
    onBack: () => void;
    showBackButton?: boolean;
}

interface FormValues extends ConfirmSignupRequest {
    password: string;
}

const ConfirmSignUpForm = ({ showBackButton = true, ...props }: Props) => {
    const router = useRouter();
    const { login, confirmSignUp } = useContext(AuthContext);
    const { logo, name } = useSubdomainLender();

    const [count, { startCountdown, resetCountdown }] = useCountdown({
        countStart: 120,
        intervalMs: 1000,
    })

    const formik = useFormik({
        validationSchema: confirmSignUpFormValidationSchema,
        initialValues: {
            username: props.username ?? '',
            password: props.password ?? '',
            provider: props.provider ?? '',
            confirmationCode: '',
        },
        onSubmit: async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
            const lRequest: ConfirmSignupRequest = {
                username: values.username,
                confirmationCode: values.confirmationCode,
                provider: props.provider,
            };
            formikHelpers.setSubmitting(true);
            try {
                const lSecurityInfo = await confirmSignUp(lRequest);

                if (lSecurityInfo.success) {
                    const lLoginRequest = {
                        username: values.username,
                        password: values.password,
                        remember: false,
                        timestamp: format(new Date(), 'HH:mm:ss'),
                    }
                    if (!!values.username && !!values.password) {
                        try {
                            const lLoginResponse = await login(lLoginRequest);
                            if (lLoginResponse.success) {

                                if (router.query.url) {
                                    await fetch(decodeURIComponent(String(router.query.url)))
                                }
                                formikHelpers.setSubmitting(false);
                                // this redirect was removed
                                // because we are already redirecting in 
                                // the guest guard
                                // router.push({
                                //     pathname: Route.HOME,
                                // })
                            } else {
                                toast({
                                    type: 'error',
                                    content: lLoginResponse.message
                                });
                            }
                        } catch (error) {
                            formikHelpers.setSubmitting(false);
                            toast({
                                type: 'error',
                                content: 'Login failed, please check your credentials'
                            })
                        }
                    } else {
                        if (router.query.url) {
                            await fetch(decodeURIComponent(String(router.query.url)))
                        }
                        toast({
                            type: 'success',
                            content: 'Your account has been confirmed, please login'
                        })
                        formikHelpers.setSubmitting(false);
                        router.push(Route.LOGIN)
                    }
                } else {
                    formik.setFieldError('confirmationCode', "The verification code is incorrect");

                    formikHelpers.setSubmitting(false);
                    toast({
                        type: 'error',
                        content: lSecurityInfo.message
                    })
                }
            } catch (error) {
                console.error(error);

            }

        },
    });

    const handleSendResendCode = async () => {
        const result = await api.resendConfirmationCode(formik.values.username, formik.values.provider);
        if (result?.success) {
            resetCountdown()
            startCountdown()
            toast({
                type: 'success',
                content: props.provider ? 'New code sent' : 'Confirmation code has been sent'
            })
        } else {
            toast({
                type: 'error',
                content: result?.message
            })
        }
    }


    const handleVerificationCodeChange = async (value: string) => {
        await formik.setFieldValue('confirmationCode', value);
        if (value.length === 6) {
            formik.handleSubmit()
        }
    }

    const usernameMasked = formik.values.username.replace(/^(.{3})(.*)(.{3})$/, '$1***$3');

    useEffect(() => {
        startCountdown()
    }, [startCountdown])


    const confirmationCodeError = formik.touched.confirmationCode && !!formik.errors.confirmationCode;

    return (<form
        noValidate
        onSubmit={formik.handleSubmit}
        className="max-w-lg w-full"
    >
        {(!props.provider && showBackButton) && <Box sx={{
            position: 'fixed',
            zIndex: 2,
            top: {
                xs: 16,
                sm: 32,
                md: 80
            },
            left: {
                xs: 16,
                sm: 32,
                md: 64
            }
        }}>
            <BackButton onClick={props.onBack} />
        </Box>}
        <div className="gap-6 flex flex-col justify-center text-center">
            <CompanyLogo logo={logo} name={name} />
            <Text size="xl" weight="medium">
                {props.provider ? "Verify your email" : "Confirm Sign up"}
            </Text>
        </div>
        <Text size="sm" variant="secondary" className="pt-2 text-center">
            Please enter the verification code sent to the email {usernameMasked}
        </Text>
        <Box
            sx={{
                mt: 4,
                display: 'flex',
                justifyContent: 'center',
                '& .verification-input-container': {
                    width: 'auto'
                },
                '& .verification-input-character': {
                    width: 48,
                    height: 48,
                    borderRadius: themeV2.shape.borderRadius.input,
                    borderColor: themeV2.neutral.grey[80],
                    backgroundColor: 'white',
                    flexGrow: 'unset',
                    flexBasis: 'unset',
                    fontSize: 16,
                    lineHeight: '48px',
                    ...(confirmationCodeError && {
                        borderColor: themeV2.colors.red[50],
                    })
                },
                '& .verification-input-character-selected': {
                    outlineStyle: 'solid',
                    outlineWidth: 1,
                    outlineColor: themeV2.colors.blue[100],
                    borderColor: themeV2.colors.blue[100],
                    color: themeV2.colors.blue[100],
                }
            }}>
            <div className="flex flex-col">
                <VerificationInput
                    autoFocus
                    length={6}
                    placeholder='-'
                    onChange={handleVerificationCodeChange}
                    value={formik.values.confirmationCode ? formik.values.confirmationCode : ''}
                    classNames={{
                        container: "verification-input-container",
                        character: "verification-input-character",
                        characterInactive: "verification-input-character-inactive",
                        characterSelected: "verification-input-character-selected",
                    }} />
                {confirmationCodeError && <Text variant="destructive" size="sm" className="pt-2 w-full text-left">
                    {formik.errors.confirmationCode}
                </Text>}
            </div>
        </Box>
        {count > 0 && <Text center size="sm" className="py-9">
            Code expires in <Text as="span" variant="blue" size="sm" fontWeight="medium">
                {getPrettyCountdownTime(count)}
            </Text>
        </Text>}
        {count === 0 && <Text size="sm" className="py-9 text-destructive">
            Code expired. Click Resend Code to get a new code
        </Text>}
        <Separator className="my-6" />
        <div className="text-center">
            <MysherpasTooltip title={!formik.values.username ? 'Enter username to request new code' : 'Request a new code'}>
                <Button
                    disabled={!formik.values.username}
                    type='button'
                    className="text-blue-100"
                    onClick={handleSendResendCode}
                    variant="ghost">
                    Resend code
                </Button>
            </MysherpasTooltip>
        </div>
    </form>)
};

export default ConfirmSignUpForm;

