import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Label from 'components/dist/atoms/Label';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import PasswordStrength from "components/dist/molecules/PasswordStrength";
import { FormikHelpers, useFormik } from 'formik';
import { useRouter } from "next/router";
import { useContext } from "react";
import { BackButton } from "src/components/common/back-button";
import { PasswordVisibilityInput } from "src/components/common/form/password-visibility-input";
import { TermsAndConditions } from "src/components/terms-and-conditions";
import { AuthContext } from "src/contexts/auth-context";
import { useSubdomainLender } from "src/contexts/subdomain-lender-context";
import { SignUpRequest } from "src/types/auth";
import { toast } from "src/utils/toast";
import { FormikAsyncValidateSchema } from "src/utils/yup-validation";

import { CompanyLogo } from "../company-logo";
import { signUpFormValidationSchema } from "./sign-up-form-validation";

interface Props {
    onSubmitSuccess?: (values: SignUpFormValues) => void;
    onBackClick?: () => void;
    showLogo?: boolean;
    showBackButton?: boolean;
    title?: string;
}

export interface SignUpFormValues {
    email: string,
    givenName: string,
    familyName: string,
    password: string,
    username: string,
    provider?: string,
}

export const SignUpForm = (props: Props) => {
    const {
        onSubmitSuccess,
        onBackClick,
        showLogo = true,
        title = 'Enter your details',
        showBackButton = true
    } = props;
    const { signUp } = useContext(AuthContext);
    const { logo, name } = useSubdomainLender();
    const { query } = useRouter();
    const queryEmail = query?.email ? String(query.email) : '';
    const queryGivenName = query?.givenName ? String(query.givenName) : '';
    const queryFamilyName = query?.familyName ? String(query.familyName) : '';

    const formik = useFormik({
        validate: FormikAsyncValidateSchema(signUpFormValidationSchema),
        initialValues: {
            email: queryEmail,
            givenName: queryGivenName,
            familyName: queryFamilyName,
            password: '',
            username: ''
        },
        onSubmit: async (values: SignUpFormValues, formikHelpers: FormikHelpers<SignUpFormValues>) => {
            formikHelpers.setSubmitting(true);
            const lRequest: SignUpRequest = {
                password: values.password,
                email: values.email,
                givenName: values.givenName,
                familyName: values.familyName,
                invitationId: null,
                url: query.url,
            };

            try {
                const lSecurityInfo = await signUp(lRequest);

                if (lSecurityInfo.success === true) {
                    toast({
                        type: 'success',
                        content: `SignUp successful. Please check your email at ${lSecurityInfo.destination} for a confirmation code.`,
                    })
                    onSubmitSuccess?.({
                        ...values,
                        username: lSecurityInfo.username
                    });
                } else {
                    toast({
                        type: 'error',
                        content: lSecurityInfo.message
                    })
                }

            } catch (error) {
                console.error(error);
            }
            formikHelpers.setSubmitting(false);
        },
    });
    return (<form onSubmit={formik.handleSubmit} className="sm:w-550 text-center px-5 sm:px-0">

        {showBackButton && (
            <Box sx={{
                position: 'fixed',
                top: {
                    xs: 16,
                    sm: 32,
                    md: 80
                },
                left: {
                    xs: 16,
                    sm: 32,
                    md: 64
                },
                zIndex: 2
            }}>
                <BackButton onClick={onBackClick} />
            </Box>
        )}
        <div className="gap-6 flex flex-col">
            {showLogo && (<CompanyLogo logo={logo} name={name} />)}
            <Text size="xl" weight="medium">
                {title}
            </Text>
        </div>
        <div className="w-full sm:w-480 mx-auto mt-10 flex flex-col text-left">
            <Stack space="sm" className="mt-4">
                <Label variant="secondary" htmlFor="givenName" required>
                    First Name
                </Label>
                <Input
                    wrapClassName='focus-within:border-blue-100 focus:border-blue-500'
                    id="givenName"
                    placeholder="First Name"
                    value={formik.values.givenName}
                    error={formik.touched.givenName && Boolean(formik.errors.givenName?.length)}
                    onChange={formik.handleChange}
                    name='givenName'
                    disabled={!!queryGivenName}
                    autoComplete='given-name'
                    data-testid="givenName"

                />

                {(formik.touched.givenName && formik.errors.givenName?.[0]) &&
                    <Text as="div" className="flex items-center gap-1" variant="destructive" size="xs">
                        <Icon name="InfoEmpty" width={12} height={12} strokeWidth={1.5} />
                        {formik.errors.givenName?.[0]}
                    </Text>
                }
            </Stack>
            <Stack space="sm" className="mt-4">
                <Label variant="secondary" htmlFor="familyName" className="text-left" required>
                    Last Name
                </Label>
                <Input
                    id="familyName"
                    placeholder="Last Name"
                    wrapClassName='focus-within:border-blue-100 focus:border-blue-500'

                    value={formik.values.familyName}
                    error={formik.touched.familyName && Boolean(formik.errors.familyName?.length)}

                    onChange={formik.handleChange}
                    name='familyName'
                    disabled={!!queryFamilyName}
                    autoComplete='family-name'
                    data-testid="familyName"
                />
                {(formik.touched.familyName && formik.errors.familyName?.[0]) &&
                    <Text as="div" className="flex items-center gap-1" variant="destructive" size="xs">
                        <Icon name="InfoEmpty" width={12} height={12} strokeWidth={1.5} />
                        {formik.errors.familyName?.[0]}
                    </Text>
                }
            </Stack>
            <Stack space="sm" className="mt-4">
                <Label variant="secondary" htmlFor="email" required>
                    Email Address
                </Label>
                <Input
                    id='email'
                    name='email'
                    placeholder="Email Address"
                    wrapClassName='focus-within:border-blue-100 focus:border-blue-500'
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email?.length)}
                    onChange={formik.handleChange}
                    disabled={!!queryEmail}
                    autoComplete='email'
                    data-testid="email"
                />
                {(formik.touched.email && formik.errors.email?.[0]) &&
                    <Text as="div" className="flex items-center gap-1" variant="destructive" size="xs">
                        <Icon name="InfoEmpty" width={12} height={12} strokeWidth={1.5} />
                        {formik.errors.email?.[0]}
                    </Text>
                }
            </Stack>

            <Stack space="sm" className="mt-4">
                <Label variant="secondary" htmlFor="password" required>
                    Create Password
                </Label>
                <PasswordVisibilityInput
                    id='password'
                    name='password'
                    placeholder="Enter Password"
                    data-testid="password"
                    autoComplete='current-password'
                    onChange={(e) => {
                        formik.setFieldTouched('password');
                        formik.handleChange(e);
                    }}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password?.length)}
                />
                {(formik.values.password === '' && formik.touched.password && formik.errors.password?.[0]) &&
                    <Text as="div" className="flex items-center gap-1" variant="destructive" size="xs">
                        <Icon name="InfoEmpty" width={12} height={12} strokeWidth={1.5} />
                        {formik.errors.password?.[0]}
                    </Text>
                }
            </Stack>
            <Stack className="mt-1 relative">
                <Stack className=" absolute z-10 sm:w-480 w-full">
                    <PasswordStrength
                        password={formik.values.password}
                    /></Stack>
            </Stack>

            <div className="w-full pt-5 pb-2">
                <Button
                    className="w-full bg-blue-100 hover:bg-blue-60 mt-10"
                    data-testid='login-button'
                    loading={formik.isSubmitting}
                    type='submit' >
                    Sign up
                </Button>
            </div>
        </div>

        <Typography variant='body1' color='secondary'>
            {"By Signing up, you agree to the "}
            <TermsAndConditions />
            {" of mysherpas"}
        </Typography>
    </form>
    )
};
